/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { MagnifyingGlass, XCircle } from 'phosphor-react';

import InputField from '../../../components/fields/input';

import Spinner from '../../../components/loader/spinner';

import { fetchResultsSearchNovo } from '../../../services/apis/business/result';

const race = {
  id: '350ee1c3-9e43-4273-900a-93ee51c63fec',
  name: 'Novohealth 5K',
};

function Search() {
  const navigate = useNavigate();

  const [header, setHeader] = useState([]);
  const [results, setResults] = useState([]);
  const [searched, setSearched] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { control, setValue, watch } = useForm({
    defaultValues: {
      search: '',
    },
  });

  const search = watch('search');

  const resultsSearch = useCallback(async (orgID, searchTerm) => {
    try {
      const response = await fetchResultsSearchNovo(orgID, searchTerm);

      const { results: list, header: tableHeader } = response;

      setHeader(tableHeader);
      setResults(list);
      setSearched(true);
      setFetching(false);
    } catch (error) {
      setFetching(false);
    }
  }, []);

  const handleSearchKeyDown = useCallback(
    code => {
      if (code === 13) {
        if (search) {
          setResults([]);
          setFetching(true);

          resultsSearch(race.id, search);
        }
      }
    },
    [search],
  );

  const handleSearchClick = useCallback(() => {
    if (search) {
      setResults([]);
      setFetching(true);

      resultsSearch(race.id, search);
    }
  }, [search]);

  const handleSearchCancel = useCallback(() => {
    setValue('search', '');
    setResults([]);
    setSearched(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>{race?.name ? `${race?.name} | Search History` : 'Loading...'}</title>
      </Helmet>
      <div className="relative">
        <div
          className="home absolute w-full h-48 bg-center bg-contain bg-no-repeat"
          style={{
            backgroundImage: 'url(/assets/images/novoHealth-cover.jpg)',
          }}>
          <div className="w-full h-52 absolute opacity-50 bg-black" style={{ zIndex: 1 }} />
        </div>
        <div className="sticky" style={{ zIndex: 2 }}>
          <div className="novoHealth-banner bg-transparent h-48">
            <p className="title">{race?.name}</p>
            <p className="subTitle">Search History</p>
          </div>
        </div>
      </div>
      <div className="container max-w-4xl mx-auto pt-6 px-6 md:px-8 mt-4">
        <div className="flex items-center">
          {searched && (
            <button
              type="button"
              className="button button--text-only !px-0"
              style={{ marginRight: '10px' }}
              onClick={() => handleSearchCancel()}>
              <XCircle size={20} weight="bold" />
            </button>
          )}
          <InputField
            name="search"
            control={control}
            placeholder="Search By Name or BIB"
            onKeyDown={event => handleSearchKeyDown(event.keyCode)}
          />
          <button type="button" className="button ml-2" onClick={() => handleSearchClick()}>
            <MagnifyingGlass size={18} weight="bold" />
          </button>
        </div>
      </div>
      <div className="container max-w-5xl mx-auto pb-6 px-6">
        {fetching && (
          <div className="mt-8">
            <Spinner size="medium" />
          </div>
        )}
        {!fetching &&
          (results.length > 0 ? (
            <>
              <div className="table-responsive bg-white shadow-xl rounded-md p-3 mt-8 mb-6 border border-gray-300 no-scrollbar">
                <table className="table table-hover">
                  <thead>
                    <tr className="border border-t-0 border-r-0 border-l-0 border-b-2">
                      {header.map(item => (
                        <th key={item.name} scope="col">
                          <span className="text-xs text-black font-semibold">{item.name}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="text-xs">
                    {results.map(result => (
                      <tr
                        key={result.name}
                        className="transition-colors duration-500"
                        onClick={() =>
                          navigate(
                            `/results/details?raceID=${result.raceDetails.id}&event=5K&bibNo=${result.bibNo}&name=${result.name}`,
                          )
                        }>
                        {Object.keys(result).map(item => (
                          <td key={item}>
                            {typeof result[item] === 'string' || typeof result[item] === 'number'
                              ? result[item]
                              : result[item].name}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {!searched && null}
            </>
          ) : (
            searched && (
              <div className="table-responsive lg:px-20 py-8">
                <p className="mb-1 text-md">
                  <strong>No Results Found</strong>
                </p>
                {/* <p className="text-sm">
                We couldn&apos;t find any results matching your criteria. Please try toggling the
                event dropdown and check your selections once again. If you still can&apos;t find
                what you&apos;re looking for, it&apos;s possible that the result is not available
                at this time.
              </p> */}
              </div>
            )
          ))}
      </div>
    </>
  );
}

export default Search;
